@media (max-width: 1500px) {
    .nav-padding {
        padding: 1rem 4.8%;
    }

    .stripe {
        left: 5.5%;
    }

    .cards-wrapper {
        justify-content: space-between;
    }

    .aboutMeBonus {
        flex-direction: row;
    }

    .aboutTitle {
        flex-direction: row;
    }

    .case-container {
        margin-bottom: var(--spacing-xl);
    }

}

@media (max-width: 1300px) {
    :root {
        --content-width: 950px;
    }

    .card {
        flex-direction: row;
        align-items: center;
        max-width: var(--content-width);
        width: 90%;
    }
    
    .cardImgWrap {
        height: 250px;
        width: 400px;
        margin: 0 var(--spacing-xl) 0 0;
    }

    .card-image {
        max-width: 350px;
        max-height: 150px;
    }

    .card-info {
        width: 100%;
    }
    
    .sm-lst {
        min-width: 20%;
        margin-bottom: var(--spacing-sm);
    }

    .double_research {
        width: 50%;
    }

    .intro-wrapper {
        max-width: var(--content-width);
    }

    .work-section  {
        max-width: var(--content-width);
    }
}

@media (max-width: 1170px) {
    :root {
        --content-width: 800px;
    }

    .intro-img {
        height: auto;
    }

    .aboutWrapper .row {
        flex-direction: column;
    }

    .aboutMe {
        margin: 0 0 var(--spacing-lg) 0;
    }

    .aboutMe, 
    .aboutMe p{
        max-width: 700px;
    }
    
    .aboutMe .row {
        align-items: flex-start;
    }

    .aboutMeBonus {
        width: var(--content-width);
    }

    .feature {
        max-width: var(--content-width);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .featurePic {
        align-content: center;
        min-width: 330px;
        margin-left: var(--spacing-xxl);
    }

    .affinity-diagram {
        width: 100%;
    }
    
    .double_researchWrap {
        justify-content: space-between;
    }

    .double_research {
        width: 45%;
    }

    .visual-identity {
        max-width: 80%;
    }

    .feedback.row {
        align-items: center;
        flex-direction: column;
    }

    .feedback-img {
        max-width: 60%;
    }

    .intro-text h1 {
        font-size: 3.3rem;
    }

    .card {
        margin: var(--spacing-sm) 0;
    }

}

@media (max-width: 1000px) {

    .nav-padding {
        padding: 1rem 1rem;
    }

    .stripe {
        left: 1.8rem;
    }

    .splash-wrap {
        height: 350px;
    }

    .piece-splash {
        max-height: 300px;
    }

    .card {
        flex-direction: row;
        align-items: center;
        max-width: var(--content-width);
        width: 90%;
    }
    
    .cardImgWrap {
        height: 150px;
        width: 300px;
        margin: 0 var(--spacing-xl) 0 0;
    }

    .card-image {
        max-width: 175px;
        max-height: 150px;
    }

    .card-info {
        width: 100%;
    }

    .intro-wrapper {
        flex-direction: column-reverse;
        justify-content: center;
    }

    .intro-img {
        margin-bottom: var(--spacing-xl);
    }
    
}

@media (max-width: 991px) {
    :root {
        --content-width: 800px;
        --scroll-offset: 0.65;
    }

    .questions h6 {
        font-size: 18px;
    }

    .questions {
        margin: 0 var(--spacing-xl) 0 0;
    }

}

@media (max-width: 930px) {
    :root {
        --content-width: 700px;
    }

    .featurePic {
        margin-left: 0;
    }

    .feature {
        max-width: var(--content-width);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .featureText {
        max-width: 100%;
    }

    .featurePic {
        width: 100%;
    }

    .md-lst {
        min-width: 0px;
        width: 50%;
    }

}

@media (max-width: 850px) {
    :root {
        --content-width: 650px;
    }

    .splash-wrap {
        height: 300px;
    }

    .piece-splash {
        max-height: 250px;
    }
    
    .cardImgWrap {
        display: none;
    }

    .card-image {
        display: none;
    }

    .work-section {
        max-width: var(--content-width);
        margin-bottom: var(--spacing-xl);
    }

    .cards-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }

}

@media (max-width: 798px) {
    :root {
        --content-width: 600px;
    }
    .summary.row {
        flex-wrap: wrap;
    }

    .aboutMeBonus {
        flex-direction: column;
    }

    .bonusList {
        margin: 0 0 var(--spacing-md) 0;
    }
    
    .sm-lst {
        min-width: 100%;
        margin-bottom: var(--spacing-sm);
    }

    .Pcol2 h6 {
        margin-top: var(--spacing-md);
        margin-bottom: var(--spacing-xs);
    }

    .researchQA.row.rQA2,
    .researchQA.row {
        flex-direction: column;
        height: auto;
        margin: 0;
    } 

    .questions {
        margin: var(--spacing-xl) auto 0;
    }
}

@media (max-width: 750px) {
    :root {
        --content-width: 500px;
        --scroll-offset: 0.71;
    }

    .intro-text h1 {
        font-size: 2.8rem;
    }

    .stripe {
        display: none;
    }

    .card-image ,   
    .cardImgWrap {
        display: flex;
        margin: 0;
    }

    .card {
        flex-direction: column;
        justify-content: center;
        width: 300px;
    }

    .card-tag {
        display: none;
    }

    .section-line.mb-xl.sel-wk {
        width: 104%;
        margin-left: -2%;
    }

    .footerContent {
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
    }

    .footerLinks {
        margin: var(--spacing-md) 0 0 0;
    }

    .footerLinks a {
        margin: 0 var(--spacing-md) 0 0;
    }

    .logo-text {
        display: none;
    }

    .nav-padding {
        justify-content: flex-start;
        padding: 1rem 1.5rem;
    }

}

@media (max-width: 600px) {
    :root {
        --content-width: 400px;
        --scroll-offset: 0.68;
    }

    .personaSec .row {
        flex-direction: column;
    }

    .overview.row {
        flex-direction: column;
    }

    .overview.row.mb-xl {
        margin-bottom: var(--spacing-xs);
    }

    .md-lst {
        padding: 0;
        margin-bottom: var(--spacing-md);
    }

    .Pcol2 {
        margin: 0;
    }
    
    .aboutTitle {
        flex-direction: column;
    }

    .intro-text h1 {
        font-size: 2.8rem;
    }

    .stripe {
        display: none;
    }

    .card-image ,   
    .cardImgWrap {
        display: flex;
        margin: 0;
    }

    .card {
        flex-direction: column;
        justify-content: center;
        width: 300px;
    }

    .card-tag {
        display: none;
    }

    .section-line.mb-xl.sel-wk {
        width: 104%;
        margin-left: -2%;
    }

    .footerContent {
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
    }

    .footerLinks {
        margin: var(--spacing-md) 0 0 0;
    }

    .footerLinks a {
        margin: 0 var(--spacing-md) 0 0;
    }

    .logo-text {
        display: none;
    }

    .nav-padding {
        justify-content: flex-start;
        padding: 1rem 1.5rem;
    }

}