.aboutWrapper {
    max-width: var(--content-width);
    margin: 135.22px auto 100px;
}

.aboutWrapper .row {
    align-items: center;
}

.aboutMe {
    margin: 0 var(--spacing-xl) var(--spacing-xl) 0;
}

.aboutTitle {
    display: inline-flex;
}

.highlight {
    color: transparent;
    background: var(--dark-higlight);
    background-clip: text;
}

.aboutBody {
    
}

.aboutBody p {
    margin-bottom: var(--spacing-md);
    max-width: 500px;
    
}

.aboutImgWrap {
    margin-bottom: var(--spacing-xl);
}

.aboutImg {
    width: 350px;
    border: 3px var(--border) solid;
    height: auto;
}

.aboutImg::before {

}

.aboutMeBonus {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.bonusList {
    
}

.bonusTitle {

}

.bonusList ul {
    
}

.bonusList li {
    list-style: none;
    margin-bottom: var(--spacing-xs);
}

.more {
    margin-top: var(--spacing-md);
}