@import url('../components/Global.css');

* {
  text-decoration:none;
}

.navbar-container {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  color: var(--copy-light);
  font-family: var(--heading-font-family);
  box-shadow: var(--box-shadow);
  background: var(--background-light);
}

.nav-padding {
  padding: 1rem 8.8%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-container.hidden {
  transform: translateY(-100%);
}

.navbar-container a {
  color: var(--copy-light);
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo-image img {
  border-radius: 50%;
  width: 3rem;
}

.logo-name {
  font-size: 1.4rem;
  font-weight: 700;
  margin-left: 0.5rem;
}

.logo-title {
  font-size: 0.9rem;
  margin-left: 0.5rem;
}

nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-weight: 400;
}

nav a {
  text-decoration: none;
  color: inherit;
}

nav a:hover {
  -webkit-transition: .4s;
  transition: .4s;
  font-weight: 500;
  color: var(--primary-dark);
}


nav a:active {
  font-weight: 500;
}


/* The switch - the box around the slider 
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
  }
  
  /* Hide default HTML checkbox 
  .switch input {
  opacity: 0;
  width: 0;
  height: 0;
  }
  
  /* The slider 
  .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  }
  
  .slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: #fff;
  -webkit-transition: .4s;
  transition: .4s;
  }
  
  input:checked + .slider {
  background-color: #555;
  }
  
  input:focus + .slider {
  box-shadow: 0 0 1px #555;
  }
  
  input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
  }
  
  /* Rounded sliders 
  .slider.round {
  border-radius: 34px;
  }
  
  .slider.round:before {
  border-radius: 50%;
  }
  
  /* Toggle button icon styles 
  .toggle-container {
  display: flex;
  align-items: center;
  }
  
  .toggle {
  margin: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  height: 32px;
  width: 32px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  }
  
  .toggle-icon {
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #fff;
  transition: background-color 1s ease-in-out;
  }
  
  .toggle-icon:before {
  content: '';
  position: absolute;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #111;
  transition: transform 1s ease-in-out;
  }
  
  .toggle.dark .toggle-icon {
  background-color: #333;
  }
  
  .toggle.dark .toggle-icon:before {
  transform: translate(-50%, -50%) translateX(8px);
  }

  .toggle-container {
    margin: 0px 0px 0px 15px;
    display: none;
  }
*/

.link-container {
  width: 305px;
  display: flex;
  justify-content: space-around;
}