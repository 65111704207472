@import url('../components/Global.css');

.card {
  padding: var(--spacing-lg);
  margin: var(--spacing-sm);
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  border: 3px solid var(--button-light);
  border-radius: var(--round);
  background: var(--background-light);
  transition: ease-in-out .3s;
  box-shadow: 0 5px 10px rgba(0,0,0,.25), 0 0 5px rgba(0,0,0,.1);
}

.card h3 {
  margin: 0 0 var(--spacing-xs) 0;
}

.card:hover {
  transform: perspective(1px) translateY(-6px); 
  box-shadow: 0 10px 20px rgba(0,0,0,.15), 0 0 5px rgba(0,0,0,.1);
}

.card:active {
  transform: perspective(1px) translateY(6px); 
  box-shadow: 0 5px 10px rgba(0,0,0,.25), 0 0 5px rgba(0,0,0,.1);
}

.card-tag {
  font-size: 0.7rem;
  font-weight: 600;
  padding: var(--spacing-xs) var(--spacing-md);
  margin: var(--spacing-xs) var(--spacing-sm) var(--spacing-sm) 0px;
  background: #b2f2e2;
  cursor: default;
  border-radius: var(--round);
  color: var(--copy-light);
}

.card-title {
  padding-top: var(--spacing-lg);
}

.tag-row {
  display: flex;
  flex-direction: row;
}

.description-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.cardImgWrap{
  height: 300px;
  width: 400px;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.card-image {
  height: auto;
  width: auto;
  max-width: 400px;
}

.mini-card {
  padding: 2rem 1rem;
  margin: 2rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
  background: var(--background-light);
  border: 5px solid var(--accent-color);
  border-radius: var(--round);
}

.mini-card-image {
  height: 200px;
  width: 250px;
}