:root {
    /*  Colors */
    --primary-light: #1972DA;
    --primary-dark: #155DB1;
    --accent-color: #68E3C4;
    --button-light: #1972DA;
    --button-dark: #155DB1;
    --background-light: #f8fafc;
    --background-dark: #1e1e1e;
    --copy-light: #111111;
    --copy-dark: #f8fafc;
    --border: #2D2D2D;

    /* Gradient */
    --button-gbg: linear-gradient(to bottom, #0065ca, #0048bb);
    --button-rgbg: linear-gradient(to bottom, #25aae1, #0065ca, #04befe, #0065ca);
    --splash-gbg: linear-gradient(to bottom, var(--background-light) 0%, var(--primary-light) 90%);
    --splash-dgbg: linear-gradient(to top, var(--background-dark) 0%, var(--primary-dark) 90%);
    --highlight-text: linear-gradient(to bottom left, #04befe, #70FACB);
    --dark-higlight: linear-gradient(to bottom left, #25aae1, #29C195);
    
    /* Typography */
    --heading-font-family: 'Montserrat', sans-serif;
    --h1-font-size: 3.5rem;
    --h2-font-size: 2.6rem;
    --h3-font-size: 2rem;
    --h4-font-size: 1.75rem;
    --h5-font-size: 1.5rem;
    --h6-font-size: 1.25rem;
    --body-font-family: 'Open Sans', sans-serif;
    --small-text-size: 0.875rem;
    --normal-text-size: 1rem;
    --large-text-size: 1.125rem;
    --extra-large-text-size: 1.25rem; 
    --line-height: 1.5;
    --heading-line-height: 1.2;
    --letter-spacing: 0.025em;
  
    /* Spacing */
    --spacing-xs: 0.35rem;
    --spacing-sm: 0.75rem;
    --spacing-md: 1rem;
    --spacing-lg: 1.5rem;
    --spacing-xl: 3rem;
    --spacing-xxl: 5rem;
  
    /* Scaling */
    --content-width: 1000px;
    --scroll-offset: 0.37;
  
    /* Border Radius */
    --round-corner: 0.25rem;
    --round: 1rem;
  
    /* Shadows */
    --box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
  }
  
  /* Global Styles */
  html, body {
    height: 100%;
    background-image: url('../../public/assets/Misc/DotBG.png');
    background-color: var(--background-light);
  }
  
  body {
    font-family: var(--body-font-family);
    font-size: var(--normal-text-size);
    line-height: var(--line-height);
    color: var(--text-color);
  }
  
  h1, h2, h3, h4 {
    font-family: var(--heading-font-family);
    line-height: var(--heading-line-height);
    letter-spacing: var(--letter-spacing);
    margin-top: 0;
    margin-bottom: var(--spacing-sm);
    color: var(--text-color);
  }

  h5, h6 {
    font-family: var(--heading-font-family);
    line-height: var(--heading-line-height);
    letter-spacing: var(--letter-spacing);
    margin-top: 0;
    margin-bottom: var(--spacing-sm);
    color: var(--text-color);
  }

  h1 {
    font-size: var(--h1-font-size);
  }

  h2 {
    font-size: var(--h2-font-size);
  }

  h3 {
    font-size: var(--h3-font-size);
  }

  h4 {
    font-size: var(--h4-font-size);
  }

  h5 {
    font-size: var(--h5-font-size);
  }

  h6 {
    font-size: var(--h6-font-size);
  }
  
  p {
    margin: 0;
    font-size: var(--normal-text-size);
  }
  
  .small-text {
    font-size: var(--small-text-size);
  }
  
  .large-text {
    font-size: var(--large-text-size);
  }
  
  .extra-large-text {
    font-size: var(--extra-large-text-size);
  }
  
  
  a {
    color: var(--primary-color);
    text-decoration: none;
    transition: color 0.3s ease-in-out;
  }
  
  a:hover {
    color: var(--secondary-color);
  }

  /* Spacing Styles */
  .mb-xs {
    margin-bottom: var(--spacing-xs);
  }
  
  .mb-sm {
    margin-bottom: var(--spacing-sm);
  }
  
  .mb-md {
    margin-bottom: var(--spacing-md);
  }
  
  .mb-lg {
    margin-bottom: var(--spacing-lg);
  }
  
  .mb-xl {
    margin-bottom: var(--spacing-xl);
  }

  .mb-xxl {
    margin-bottom: var(--spacing-xxl);
  }
  
  /* Padding Styles */
  .p-xs {
    padding: var(--spacing-xs);
  }
  
  .p-sm {
    padding: var(--spacing-sm);
  }
  
  .p-md {
    padding: var(--spacing-md);
  }
  
  .p-lg {
    padding: var(--spacing-lg);
  }
  
  .p-xl {
    padding: var(--spacing-xl);
  }
  
  /* Border Styles */
  .border {
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
  }
  
  /* Shadow Styles */
  .shadow {
    box-shadow: var(--box-shadow);
  }
  
  /* Gradient Styles */
  .gradient {
    background: linear-gradient(to bottom right, var(--primary-color), var(--secondary-color));
  }
  
  /* Button Styles 
  .btn {
    display: inline-block;
    border-radius: var(--border-radius);
    padding: var(--spacing-sm) var(--spacing-md);
    font-size: var(--base-font-size);
    line-height: var(--line-height);
    text-align: center;
    white-space: nowrap
    */
/* 
  .stripe {
    background: linear-gradient(to bottom, #68E3C4, #68E3C4 90%, rgba(0, 0, 0, 0));
    min-height: 240%;
    width: 20px;
    z-index: 0;
    position: absolute;
    top: 0;
    left: 9.5%;
  }
  */

  .stripe {
    background: var(--primary-light);
    min-height: 100%;
    min-width: 20px;
    margin-right: -20px;
    z-index: 0;
    margin-top: -7px !important;
    position: relative;
    top: 0;
    left: 9.5%;
  }

  .center {
    margin: auto !important;
  }
  
  .hidden {
    display: none !important;
}
