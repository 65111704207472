@import url('../components/Global.css');

.intro {
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 1;
}

.intro-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  max-width: 1000px;
  margin: auto;
}

.introduction {
  font-size: 1.25rem;
  line-height: 1.5;
  margin-bottom: 20px;
  max-width: 700px;
}

.intro-img {
  height: auto;
  width: 350px;
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--spacing-lg);
}

.button {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: var(--h5-font-size);
  padding: var(--spacing-md) var(--spacing-lg);
  background: var(--background-light);
  border: 3px solid var(--button-light);
  border-radius: var(--round);
  transition: ease-in-out .3s;
  box-shadow: 0 5px 10px rgba(0,0,0,.25), 0 0 5px rgba(0,0,0,.1);
}

.text {
  color: transparent;
  background: var(--dark-higlight);
  background-clip: text;
}

.button:hover {
  transform: perspective(1px) translateY(-6px); 
  box-shadow: 0 10px 20px rgba(0,0,0,.15), 0 0 5px rgba(0,0,0,.1);
}

.button:active {
  transform: perspective(1px) translateY(6px); 
  box-shadow: 0 5px 10px rgba(0,0,0,.25), 0 0 5px rgba(0,0,0,.1);
}

.button::after {
  border-radius: 50%;
  left: -50%;
  right: -50%;
  top: -150%;
  bottom: -150%;
  line-height: 8.34em;
  transform: scale(0, 0);
}

button:hover::after {
  transform: scale(1, 1);
}

img {
  width: 100%;
  height: auto;
}



/*
.button {
  font-size: 1rem;
  font-weight: 600;
  padding: var(--spacing-md) var(--spacing-md);
  margin: 0px var(--spacing-md) var(--spacing-md) 0px;
  background: var(--button-gbg);
  border-radius: var(--round-corner);
  color: var(--copy-dark);
  min-width: 200px;
  text-align: center;
  transition: all 0.3s ease;
  border: none;
}
*/