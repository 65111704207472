.section-line {
    background: linear-gradient(to right, rgba(0, 0, 0, 0), var(--accent-color) 10%, var(--accent-color) 90%, rgba(0, 0, 0, 0));
    height: 7px;
}

.consec {
    margin-bottom: var(--spacing-xl);
}

.splash-wrap {
    display: flex;
    width: 100%;
    height: 450px;
    margin-top: 87.22px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.piece-splash {
    height: auto;
    width: auto;
    max-height: 425px;
}

.nm {
    margin: 0px !important;
}

.case-container {
    max-width: var(--content-width);
    margin: 0px auto 100px;
    display: flex;
    flex-direction: column;
}

p {
    max-width: var(--content-width);
}

.row {
    display: flex;
    flex-direction: row;
}

.col {
    display: flex;
    flex-direction: column;
}

.summary.row {
    max-width: var(--content-width);
    justify-content: space-between;
}

.section {
    margin: var(--spacing-xl) 0;
}

.section-title {
    margin: var(--spacing-xs) 0;
    font-size: 18px;
    font-weight: 700;
    color: var(--primary-light)
}

.sm-lst {
    min-width: 220px;
    padding: 0 40px 0 0;
}

.sm-lst-head {
    font-family: ;
    margin: var(--spacing-xs) 0;
    font-size: 16px;
    font-weight: 700;
}

li {
    margin-bottom: var(--spacing-sm);
}

.process-img {
    height: auto;
    width: 90%;
    margin-top: var(--spacing-lg);
}

.overview.row {
    max-width: var(--content-width);
    justify-content: space-between;
}

.Olist li {
    list-style: square;
    list-style-position: outside;
    margin-left: 20px;
}

.hmw li {
    list-style: none;
}

.md-lst {
    min-width: 360px;
    padding: 0 40px 0 0;
}

.md-lst-head {
    font-family: ;
    margin: var(--spacing-sm) 0;
    font-size: 20px;
    font-weight: 700;
}

.personaImgWrap {    
    height: 220px;
    width: 220px;
    display: flex;
    border: 3px var(--border) solid;
    overflow: hidden;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-bottom: var(--spacing-md);
}

hli {
    color: var(--primary-light);
    font-weight: 500;
}

.persona-img {
    height: auto;
    width: auto;
    max-width: 260px;
}

.persona2 {
    margin-top: var(--spacing-xl);
}

.Pcol1 {
    display: flex;
    justify-content: center;
}

.Pcol2 {
    margin: 0 0 0 var(--spacing-xl);
    display: flex;
    justify-content: space-evenly;
}

.frustration li,
.interest li{
    list-style: none;
    margin: 0 0 var(--spacing-xs) 0;
}

ul {
    margin: 0;
    padding: 0;
}

.research-img {
    height: auto;
    width: 100%;
    margin: var(--spacing-md) 0;
}

.research .row {
    align-items: center;
}

.findings h6 {
    min-width: 30px;
    text-align: center;
    margin: 0px;
}

.research li {
    list-style: square;
    list-style-position: inside;
}

.double_researchWrap {
    display: flex;
    flex-direction: row; 
    justify-content: space-evenly;
}

.double_research {
    width: 400px;
    height: auto;
    margin-bottom: 7rem;
}

.researchQA {
    display: flex;
    justify-content: space-between;
    margin: 0 0 var(--spacing-xl);
    height: 250px;
}

.rQA2 {
    margin-top: var(--spacing-xxl);
}

.research .questions {
    display: flex;
    flex-direction: column;
    margin: 0 var(--spacing-xl) 0;
}

.questions {
    display: flex;
    width: 300px;
    margin: 0 var(--spacing-lg) 0 0;
    flex-direction: column;
    justify-content: space-between;
}

.questions h6 {
    font-weight: 500;
}

.questions h2 {
    font-weight: 600;
    margin: 0 0 var(--spacing-sm) 0;
}

.questions li {
    list-style: square;
    list-style-position: inside;
    margin: 0 0 var(--spacing-xs) 0;
}

.visual-data {
    height: auto;
    width: 100%;
    /*border: 3px var(--border) solid;*/
    max-width: var(--content-width);
    margin: var(--spacing-xxl) 0 var(--spacing-xl);
}

.affinity-diagram {
    height: auto;
    width: 900px;
    margin: var(--spacing-md) auto var(--spacing-xl);
}

.user-picImgWrap {
    height: 80px;
    min-width: 80px;
    width: 80px;
    display: flex;
    overflow: hidden;
    align-items: center;
    flex-direction: column;
    margin: var(--spacing-md) 0;
    justify-content: center;
}

.user-pic {
    height: auto;
    width: auto;
    max-width: 80px;
    max-height: 80px;
}

.painpoints {
    align-items: center;
}

.research .row p {
    margin: var(--spacing-sm) var(--spacing-lg);
}

.problem-statement {
    max-width: 850px;
    margin: var(--spacing-xl) auto;
}

.problem-statement.col h4 {
    margin: 0px;
}

.WFImgWrap{
    height: auto;
    width: auto;
    display: flex;
    overflow: hidden;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

.wireframe-img {
    height: auto;
    margin: auto;
    width: auto;
    max-width: 100%;
    max-height: 600px;
    margin: var(--spacing-md) 0;
}

.designQ, .designA {
    margin: 0 0 var(--spacing-md) 0;
}

.designQ {
    font-weight: 500;
    font-size: 18px;
}

.Anumber {
    color: var(--primary-dark);
}

.visual-identity {
    height: auto;
    background: #ffffff;
    padding: 25px;
    border: 3px var(--border) solid;
    width: auto;
    margin: var(--spacing-lg) auto 0;
    max-height: 450px;
    max-width: 800px;
    
}

.featureRow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.feature {
    max-width: 450px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.feature p {
    margin: auto;
    height: auto;
}

.featureText {
    margin-bottom: var(--spacing-lg);
}

.featurePic {
    justify-content: center;
    margin-bottom: var(--spacing-xl);
}

.feature-img {
    max-height: 300px;
    width: auto;
}

.design .col {
   justify-content: center;
}

.design .col p {
    max-width: 500px;
    margin: 0 var(--spacing-xl) 0 0;
}

.nm {
    margin: 0;
}

.user-journey {
    height: auto;
    width: 100%;
    margin: var(--spacing-md) 0;
}
  
  #play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 50%;
    cursor: pointer;
  }
  
  #play-button::before {
    content: '\25B6'; 
    font-size: 24px;
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .finalVidWrap {
      position: relative;
      width: 100%;
      max-width: 400px;
      height: auto;
      margin: var(--spacing-lg) auto var(--spacing-xl);
    }
  
  .finalVidFrame {
    position: absolute;
    width: 100%;
    height: auto;
    z-index: 1;
    pointer-events: none;
  }
  
  .finalVid {
    position: relative;
    width: auto;
    margin: 38px 42px;
    max-height: 800px;
    max-width: 460px;
    height: 686px;
    z-index: 0;
  }
  
.final-design {
    height: auto;
    width: 80%;
    margin: var(--spacing-lg) auto var(--spacing-xl);
}

.challengeSection {
    display: flex;
    flex-direction: column;
    height: auto;
    margin: auto 0;
}

.challengeSection ul {
    list-style: square;
    list-style-position: outside;
    padding-left: 0;
    padding-right: 0;
}

.challengeSection .design .row {
    margin: var(--spacing-xs);
}

.challengeSection .row li,
.challengeSection .row h6 {
    width:50%;
}

.challengeListWrap .row {
    display:flex;
}

.challengeSection .row li {
    margin: 0 var(--spacing-lg);
}

li::marker {
    font-weight: bold;
}

.impact {
    max-width: 850px;
    margin: var(--spacing-xl) auto;
}

.impact .col {
    justify-content: center;
    margin: 0 var(--spacing-xl) 0 0;
}

.impact-img {
    max-height: 100px;
    width: auto;
    margin: var(--spacing-md) 0;
}

.feedback {
    display: flex;
}

.feedback p {
    max-width: 600px;
    margin: 0 var(--spacing-xl) var(--spacing-sm) 0;
}

.feedback-img {
    height: auto;
    width: 400px;
    margin: 0 var(--spacing-md) 0;
    border: 3px var(--border) solid;
}

.takeaways p {
    margin: 0 0 var(--spacing-sm) 0;
}