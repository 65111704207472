@import url('../components/Global.css');

.work-section {
  margin: 0;
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  margin-bottom: 100px;
}

.cards-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.section-line.mb-xl.sel-wk {
  width: 106%;
  margin-left: -3%;
}

.work-section .textbubble {
  width: 333px;
}

.grid-section .textbubble {
  width: 236px;
}

.textbubble {
  height: 80px;
  border: solid 5px #1972DA;
  background: var(--background-light);
  position: relative;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 20px;
  z-index: 0;
}

.textbubble:before {
  content: "";
  position: absolute;
  right: 100%;
  top: 50%-32px;
  width: 0;
  height: 0;
  border-top: 18px solid transparent;
  border-right: 32px solid #1972DA;
  border-bottom: 18px solid transparent;
}