.footer {
    width: 100%;
    display: flex;
    height: 250px;
    background-color: var(--background-dark);
    color: #a0a09f;
    flex-direction: row;
    position: relative;
    z-index: 2;
    align-items: center;
    font-family: var(--body-font-family);
}

.footerContent {
    width: var(--content-width);
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
}

.footerText {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 140px;
}

.footerMsg {
    color: transparent;
    background: var(--highlight-text);
    background-clip: text;
}

.footerNote p {
    margin-top: var(--spacing-xs);
}

.footerLinks a {
    margin-left: var(--spacing-lg);
}